import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../environments/environment';

interface HelloDto {
  message: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend';
  message$: Observable<string>;

  constructor(http: HttpClient) {
    this.message$ = http.get(`${environment.apiUrl}/hello`).pipe(
      map(response => {
        const dto: HelloDto = response as HelloDto;
        return dto.message;
      })
    );
  }
}
